import React, { useMemo } from 'react'

import { CalendarIcon, ChatIcon } from 'core/constants/icons'
import { ensureUri } from 'core/utils/urls'
import Link from 'next/link'
import { useRouter } from 'next/router'
import useMeetingSlug from 'profiles/hooks/useMeetingSlug'
import usePublicProfile from 'profiles/hooks/usePublicProfile'
import { useTranslationWithFallback } from 'profiles/utils/translations'

import styles from 'profiles/styles/components/ProfileCard.module.css'

const CalendarButton = () => {
  const { data: profile } = usePublicProfile()
  const { query } = useRouter()

  const meetingSlug = useMeetingSlug()

  const meetingHref = useMemo(() => {
    let url = `/${query.globalHandle}`

    if (meetingSlug && meetingSlug !== 'meeting') {
      url += `/meeting/${meetingSlug}`
    } else {
      url += `/meeting`
    }
    return `${url}`
  }, [meetingSlug, query.globalHandle])

  if (profile?.teamMember.calendarOnlyStatus) {
    return (
      <Link href={meetingHref} passHref legacyBehavior>
        <button
          data-testid="calendar-button"
          className={styles.MainButton}
          style={{
            background: `#${profile?.embed.theme.backgroundColor}`,
            borderColor: `#${profile?.embed.theme.foregroundColor}`,
            color: `#${profile?.embed.theme.foregroundColor}`,
          }}
        >
          <CalendarIcon marginRight={'8px'} />
          <span>{profile?.teamMember.calendarCta || 'Schedule a Meeting'}</span>
        </button>
      </Link>
    )
  }
  if (
    !profile?.teamMember.calendarUrl &&
    !profile?.teamMember.canAcceptMeetings
  ) {
    return null
  }

  const href = profile?.teamMember.calendarUrl
    ? ensureUri(profile.teamMember.calendarUrl)
    : meetingHref
  const buttonProps = {
    target: profile?.teamMember.calendarUrl ? '_blank' : '',
    rel: profile?.teamMember.calendarUrl ? 'nofollow' : '',
  }

  return (
    <Link href={href} passHref legacyBehavior>
      <button
        data-testid="calendar-button"
        className={styles.MainButton}
        style={{
          background: `#${profile?.embed.theme.backgroundColor}`,
          borderColor: `#${profile?.embed.theme.foregroundColor}`,
          color: `#${profile?.embed.theme.foregroundColor}`,
        }}
        {...buttonProps}
      >
        <CalendarIcon marginRight={'8px'} />
        <span>{profile?.teamMember.calendarCta || 'Schedule a Meeting'}</span>
      </button>
    </Link>
  )
}

const ButtonBar = () => {
  const { data: profile } = usePublicProfile()

  const handleChatNowClick = () => {
    if (typeof window === 'undefined' || !window.drift) return

    window.drift.config({
      profileMode: true,
      autoAssigneeId: profile?.teamMember.agentId,
    })

    if (profile?.teamMember.agentStatus.online) {
      window.drift.showWelcomeMessage(profile.teamMember.welcomeMessage)
    } else {
      window.drift('showAwayMessage', profile?.teamMember.awayMessage)
    }
  }

  const chatButtonCopy = useTranslationWithFallback({
    key: 'meeting.chat',
    fallback: 'Chat',
  })

  return (
    <div className={styles.MainButtonBar}>
      {!profile?.teamMember.calendarOnlyStatus && (
        <button
          className={styles.MainButton}
          style={{
            flexGrow: 1,
            background: `#${profile?.embed.theme.backgroundColor}`,
            borderColor: `#${profile?.embed.theme.foregroundColor}`,
            color: `#${profile?.embed.theme.foregroundColor}`,
          }}
          onClick={handleChatNowClick}
        >
          <ChatIcon
            marginRight={'8px'}
            iconStyle={profile?.embed.theme.iconStyle}
          />
          <span>{chatButtonCopy}</span>
        </button>
      )}

      {profile?.teamMember.enableCalendarCta !== false && <CalendarButton />}
    </div>
  )
}

export default ButtonBar
